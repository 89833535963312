import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import IosPricetag from 'react-ionicons/lib/IosPricetag'
import Layout from '../components/Layout'

export default function Template({data}) {
  const { markdownRemark: post } = data
  const mestaDesc = post.frontmatter.tags.join(', ')

  return (
    <Layout>
      <div className="post">
        <Helmet>
          <meta name="description" content={`A ${mestaDesc} project`} />
        </Helmet>

        <Link 
          to="/projects/"
          className="back-link">
          <span>⬅</span> Back to projects
        </Link>

        <h1>{post.frontmatter.title}</h1>

        <ul className="tags">
          {post.frontmatter.tags.map(tag => (
            <li key={tag}>
              <Link className="tag" to={`/tags/${tag}`}>
                <IosPricetag /> {tag}
              </Link>
            </li>
          ))}
        </ul>

        <Img sizes={post.frontmatter.featuredImage.childImageSharp.sizes} />

        <div dangerouslySetInnerHTML={{__html: post.html}} />
      </div>
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path} }) {
      html
      frontmatter {
        path
        title
        tags
        featuredImage {
          childImageSharp{
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
